@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);

.fa-2x {
  /* font-size: 20px; */
}


.fontA {
  position: relative;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin: 0 27.19 0 27.19;
  /* font-size: 20px; */

}


.main-menu:hover,
nav.main-menu.expanded {
  width: 260px;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;/* Hide scrollbar for Firefox */
  -ms-overflow-style: none;/* Hide scrollbar for IE and Edge */
  position: fixed;
}



img.main-menu.expanded {
  width: 260px;
  height: 50%;
}

.main-menu {
  /* background: var(--background-color); */
  background: white;
  border-right: 1px solid #e5e5e5;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 78.37px;
  overflow: hidden;
  /* overflow-y: auto; */
  -webkit-transition: width .1s linear;
  transition: width .1s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.main-menu>ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 260px;
}

.main-menu li>a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: scroll;
  color: black;
  font-family: 'Roboto', sans-serif;
  /* font-size: 125%; */
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all .1s linear;
  transition: all .1s linear;

}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.admin-icons {
  margin-right: 1.3rem;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 172px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

.main-menu>ul.logout {
  position: sticky;
  top: 1000px;
  width: 172px;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu li:hover>.sidenav-image-a,
nav.main-menu li.active>.sidenav-image-a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: black;
  /* background-color: #BDEAF4; */

  /* background-color:transparent; */
  /* font-weight: bold; */
}

.area {
  float: left;
  /*   background: #eee; */
  /* width: 100%; */
  height: 100%;
}

.sidenav-image-a-dashboard {
  color: #2AA8C4;
  border-right: 2px solid #2AA8C4;
}

.main-menu:hover {
  border: none;
}

.sidenav-image-a {
  color: black !important;
  border-right: none;
}

/* .sidenav-image-a-myprofile{
  color: #2AA8C4;
}
.sidenav-image-a-settings{
  color: #2AA8C4;
} */


.has-subnav:hover {
  background-color: #BDEAF4;
}


.main-menu:hover .active>.active-icons {
  border-right: 2px solid #2AA8C4;
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}