.login-Resumes_Image {
    background-repeat: none;
    float: left;
    margin: 0;
    width: max(50%, 600px);
    height: max(100vh,700px);
  }

  /* error alert message */
  .alert-errMsg-button {
    color: red;
    text-align: center;
  }

  .Login-button {
    margin-bottom: 3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    display: flex;
/* flex-direction: row; */
justify-content: center;
color: white;
align-items: center;
padding: 10px;
gap: 10px;
width: 400px;
height: 45px;
background: linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%);
border-radius: 11px;
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
border: none;
font-family: 'Roboto', sans-serif;
  }


  .login-form .outlined-basic {
    width: 400px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .login-a {
    font-style: normal;

    margin-left: 5px;
    color: #4A44A5;
    font-family: 'Roboto', sans-serif;
    font-style: italic;
  }
  
  /*===== BASE =====*/
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
  
  .login-Container .login-Resumelogo {
    width: 200px;
    height: 70px;
  
  }
  
  .login-ResumeLogo {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  #container {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  /* 
  #gsi_6856822_632398, iframe {
    margin: auto;
  } */
  
  .google-login-buttons {
    margin: auto;
  }
  
  
  
  /* .S9gUrf-YoZ4jf {
    margin: 0 auto;
  } */
  
  .login-l-form {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .login-form {
    width: 500px;
    padding: 2rem 2rem;
  }
  
  .login-form__title {
    font-weight: 400;
    /* margin-bottom: 3rem; */
  }
  
  .login-form__div {
    position: relative;
    height: 45px;
width: 465px;
margin-bottom: 1.5rem;
    /* margin-bottom: 1.5rem; */
  }

  body {
    margin: 0;
  }
  
  .login-form__input {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: var(--normal-font-size);
    border: 1px solid var(--border-color);
    border-radius: .5rem;
    outline: none;
    padding: 1rem;
    background: none;
    z-index: 1;
  }
  
  .login-form__label {
    position: absolute;
    left: 1rem;
    top: var(--center-input-label);
    padding: 0 .25rem;
    background-color: #fff;
    color: var(--input-color);
    font-size: 20px;
    transition: .3s;
  }
  
  .login-form__button {
  
    width: 100%;
    height: 100%;
    padding: .75rem 2rem;
    outline: none;
    border: none;
    background-color: var(--before-button-hover);
    color: #fff;
    font-size: var(--normal-font-size);
    border-radius: .5rem;
    cursor: pointer;
    transition: .3s;
    margin-bottom: 3rem;
  }
  
  .login-form__button:hover {
    box-shadow: 0 10px 36px rgba(0, 0, 0, .15);
    background-color: var(--after-button-hover);
  }
  
  /* POLICY IS IN THE INDEX.CSS */
  .policy {
  
    font-size: var(--medium-font-size);
    text-align: center;
  }
  
  .google-button {
    text-align: center;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
  
  .login-with-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 12px 20px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
  }
  
  .login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  .login-with-google-btn:active {
    background-color: #eee;
  }
  
  .login-with-google-btn:focus {
    outline: none;
    /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25), 0 0 0 3px #c8dafc; */
  }
  
  .login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25); */
    cursor: not-allowed;
  }
  
  /* .login-google-button1 {
    /* text-align: center; */
    /* text-align: center; */
    /* align-items: center;
    justify-content: center; */
    /* margin: 0 auto; */
  /* } */ 
  
  /* .signInButton {
    float:none;
    position:static;
    display:block;
    margin:auto;
    width:470px;
    padding-right:40px ;
  } */
  
  .login-Main-content {
    width: max(50%,600px);
    float: right;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
  
  .login-Container {
    width: max(100%,1200px);
    height: 100%;
  }
  
  /* .login-alert{
    font-size:var(--small-font-size);
    color: red;
    padding: 0 0 0 10px;
  } */
  
  
  /*Input focus move up label*/
  .login-form__input:focus+.login-form__label {
    top: -.5rem;
    left: .8rem;
    color: var(--first-color);
    font-size: 20px;
    font-weight: 500;
    z-index: 10;
  }
  
  /*Input focus sticky top label*/
  .login-form__input:not(:placeholder-shown).login-form__input:not(:focus)+.login-form__label {
    top: -.5rem;
    left: .8rem;
    font-size: 20px;
    font-weight: 500;
    z-index: 10;
  }
  
  /*Input focus*/
  .login-form__input:focus {
    border: 2px solid var(--first-color);
  }
  
  .login-Line {
    height: 1px;
    /* margin-left: 66.5%; */
    /* margin-right: 15.5%; */
    width:100%;
    margin-bottom: .5rem;
    background-color: #dadada;
  }

  .loginOrlogin {
    display: flex;
    left: 37.5px;
    text-align: center;
    margin-right: auto;
    margin-left: 2.5rem;
/* top: 23.67px; */
margin-top: 0;
color: #E6E6E6;
margin-bottom: .5rem;
  }

  .line-login {
width: 150px;
height: 0px;
left: 70.5px;
top: 23.67px;
margin-top: 10px;


/* Neutral Light Grey */

border: 1px solid #E6E6E6;

  }



  .login-with-google-btn {
    margin-top: 1.5rem;
    width: 465px;
    margin-bottom: 1rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 12px 16px 12px 42px;
    border: 1px solid #545454;;
    border-radius: 10px;
    /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25); */
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    /* background-color: #545454; */
    background-repeat: no-repeat;
    background-position: 20px 11px;
}

/* .login-with-google-btn:active {
    background-color: #eee;
} */
.login-with-google-btn:focus {
    outline: none;
    /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25), 0 0 0 3px #c8dafc; */
}
.login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #545454;
    /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25); */
    cursor: not-allowed;
}

.login-Main-content .login-name {
/* width: 94px; */
height: 44px;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 44px;
color: #000000;
text-align: center;
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 0;
margin-top: 100px;
font-family: 'Roboto', sans-serif;
}


.signInButton {
  float:none;
  position:static;
  display:block;
  margin:auto;
  width:400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: '2rem';
  font-family: 'Roboto', sans-serif;
}
.loginBtn--google {
  float:none;
  position:static;
  display:block;
  margin:auto;
  width:400px;
 
}
/* #signInGoogleButton {
  width: 645px;
}

#gsi_576418_111227 {
  width: 645px;

}

#container {
  width: 1000px;

} */

.imageFlexible {}

.apple-sign-up-button {
  display: inline-block;
  background-color: white;
  color: black;
  padding: 5px 0px 5px 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 400px;
  border: 1px solid #dadce0;
  height: 40px;
}

/* .apple-sign-up-button:hover {
  background-color: #1a1a1a;
} */


.appleIcon {
  height: 22px;
  width: 22px;
  margin-right: .3rem;
  margin-bottom: 8px;
}