/* BOXES */

.main_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.box__companyCards {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    align-items: flex-start;
    gap: 33px;
}

.box__companyName {
    width: 100%;
    height: 20%;
    /* min-height: 24px; */
}

.box__companyCardContent {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-height: 218px; */
}

.box__companyDetails {
    width: 100%;
    height: 30%;
    /* min-height: 35px; */
}

.box__actionButtons {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* min-height: 50px; */
}


/* CARDS */
.card__companyCards {
    width: 15%;
    min-width: 255px;
    height: 35%;
    min-height: 296px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: var(--Neutral-White, #FFF);
    border-radius: 10px !important;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08) !important;
}

/* TYPOGRAPHYs */
.typography__companyName {
    color: #000;

    /* Heading-5 */
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.typography__box_companyCardContent {
    height: 50%;
    width: 100%;
    color: var(--Neutral-Dark-Grey, #545454);

    /* Subheading-3 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
}

/* BUTTONS */
.button__actionButtons {
    width: 100%;
    height: 33.33%;
    display: flex;
    padding: 16px 151px 16px 152px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    box-shadow: none !important;
    border-radius: 5px !important;
    border-color: var(--Primary-Gradient, linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%));
    color: var(--Primary-Gradient, linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%));
    text-transform: none;
}