/* BOXES */

.main_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.box__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  user-select: none;
}

.box__postings {
  width: "100%";
}

/* BUTTONS */
.button__filterButtons {
  width: "fit-content" !important;
  height: 39px !important;
  border-radius: 61px !important;
  border: 1px solid var(--primary-3, #38c1df) !important;
  color: black;
  text-transform: none !important;
}

/* TYPOGRAPHIES */
.typography__header {
  color: var(--Neutral-Mid-Grey, #8b8b8b);
  text-align: center;

  /* Subheading-3 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
}
