.leftInput {
    width: 45%;
}

.rightInput {
    width: 45%;
}

.left-form-component {
    /* background-color: chocolate; */
    width: 35%;
    margin-left: 2%;
    display: flex;
    justify-content: center;
    /* margin-top: 5%; */
    height: auto;
}

.image-upload>input {
    display: none;
}

.form-align-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: aqua; */
}

.large-input-button {
    height: 70%;
}

.large-input-comp {
    height: 35%;
    /* background-color: bisque; */
}

.right-side-buttons {
    float: left;
    justify-content: flex-end;
    margin-left: auto;
    width: 40%;
    margin-top: 2%;
    /* padding-bottom: 10%; */

}

.right-side-buttons-jd {
    justify-content: center;
    margin: auto;
    width: 40%;
    margin-top: 0;
    /* padding-bottom: 10%; */

}

.right-side-buttons-bd {
    float: left;
    justify-content: flex-start;
    width: 40%;
    margin: auto;
    margin-top: 0;
    /* padding-bottom: 10%; */

}

.right-side-buttons-sku {
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    margin-top: 0;
    /* padding-bottom: 10%; */

}

.right-side-buttons-college {
    float: left;
    justify-content: center;
    margin: auto;
    width: 100%;
    margin-top: 0;
    /* padding-bottom: 10%; */

}

.inner-div-container {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.file-name-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: black;
}

.cloud-comp {
    width: 75%;
    height: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(56, 193, 223, 0.22);
    align-self: flex-start;
    align-content: flex-start;
    border-radius: 10px;

}

.cloud-comp-image {
    width: 100%;
    /* height: 10%; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(56, 193, 223, 0.22);
    align-self: flex-start;
    align-content: flex-start;
    border-radius: 10px;
}

.cloud-compjd {
    width: 75%;
    height: 25%;
    height: auto;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(56, 193, 223, 0.22);
    align-self: flex-start;
    align-content: flex-start;
    border-radius: 10px;

}

.cloud-comp-exist {
    width: 75%;
    height: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-start;
    align-content: flex-start;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid grey;
    margin: 0 auto;
}

.cloud-comp:hover {
    /* color: white; */
    border-color: black;
    border-radius: 5px;
    /* cursor: pointer; */
    border-radius: 10px
}

.Entire-form {
    margin: 0;
    justify-content: center;
    /* background-color: tomato; */
    height: auto;
    width: 98%;
    /* padding-bottom: 2%; */
}

.Entire-form1 {
    margin: 0;
    justify-content: center;
    /* background-color: tomato; */
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* padding-bottom: 2%; */
}

.headerComp {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.compTitle {
    margin: 0 auto;
    margin-top: 1%;
    font-size: 25;
    font-weight: bold;
}

.compTitlejd {
    margin: 0 auto;
    font-size: 25;
    text-align: center;
    font-weight: bold;
}

.form-group {
    /* margin-top: 2%; */
    margin-bottom: 1%;
}

.form-control {
    margin-top: 1%;
}

.btn-comp1 {
    width: 150px;
    height: 50px;
    background-color: var(--submit-btn-color);
    border-radius: 5px;
    justify-content: flex-start;
    margin-left: 3%;
}

.btn-comp2 {
    width: 150px;
    height: 50px;
    background-color: var(--submit-btn-color);
    border-radius: 5px;
    margin-left: .75rem;
    position: absolute;
    left: 8%;
    bottom: 4%;
}

.btn-comp {
    width: 150px;
    height: 50px;
    background-color: var(--submit-btn-color);
    border-radius: 5px;
    margin-top: 2%;
    /* justify-content: flex-end; */
    margin-left: 24px;
    margin-bottom: 2%;

}

.btn-comp3 {
    width: 150px;
    height: 50px;
    background-color: var(--submit-btn-color);
    border-radius: 5px;
    position: relative;
    justify-content: flex-start;
    /* margin-top: 20px; */
    margin-left: 15px;
    /* margin-left: 30%;
    margin-bottom: 6.5%; */

}

.btn-image {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: .5rem;
}

#btn-image1 {
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-image:hover {
    border: 1px solid var(--submit-btn-color);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

/* input[type='file'] {
    color: transparent;
  } */
/* input[type="file"]
  { 
      width: 112px;        
  } */
.add-icon {
    border: 1px solid grey;
}


.submit-btn {
    /* margin: 0 auto; */
    color: white;
    width: 100%;
    justify-content: flex-start;
    height: 100%;
}

.deleteIcon {
    color: red;
}

.submit-btn:hover {
    border-color: var(--submit-btn-color);
    cursor: pointer;

}

#selectList {
    margin-right: 90px;
}

#selectList1 {
    padding-right: 50px;
}

.cloud-icon {
    align-self: center;
    align-content: auto;
    margin: 0 auto;
}

.upload-text {
    border-color: rgba(56, 193, 223, 0.22);
    color: black;
    text-align: center;
}

.imageFiles-dont-exist {
    height: 60px;
    margin-top: 20px;
    text-align: center;
}

.imageFiles--exist {
    height: 40px;
    margin-top: 20px;
    text-align: center;
    padding: 'auto';
}

.batch-button {
    width: 116px;
    height: 39px;
    background: linear-gradient(132.73deg, #2AA8C4 16.39%, #38C1DF 100%);
    border-radius: 10px;
    border: none;
    color: #fff;
}
.batch-button:disabled {
    background: #8B8B8B;
  width: 116px;
  height: 39px;
  color: white;
  border-radius: 10px;
  border: none;
}