.myprofile_form_inputs_acads {
    /* position: relative;
    display: flex; */
    width: 50%;
    color: grey;
    /* border-bottom: none; */
}

.myprofile_form_inputs_acads-1 {
    /* float: right; */
    width: 50%;
}

.myprofile_acad_table {
    /* margin-bottom: 50%; */
    justify-items: center;
    align-items: center;

    /* border: 1px solid #eee; */
}

.myprofile_acad_table>tr {
    border-bottom: none;
}

.myprofile_form_acad {
    width: max(40%, 90.5%);
    margin: 0 0 0 7.5%;
    padding: 2rem;
    border: 2px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2%;

}

.myprofile_form_acad_modal {
    width: max(40%, 70%);
    margin: 0 0 0 7.5%;
    padding: 2rem;
    /* border: 2px solid rgba(0, 0, 0, 0.05); */
    margin-bottom: 2%;

}

.myprofile_input_fields_acad {
    /* border: 1px solid rgba(0, 0, 0, 0.05); */
    background-color: transparent;
    flex-flow: column;
    width: auto;
    /* justify-content: center;
    align-items: center;
    text-align: center;*/
}

.myprofile_acad_div {
    width: auto;
    vertical-align: middle;
}

.myprofile_form_inputs_acads p {
    text-align: left;
}

.myprofile_form_input_acads {
    position: relative;
    top: 0;
    left: 0;
    width: 250px;
    height: 40px;
    /* height: 100%; */
    font-size: var(--normal-font-size);
    border: 1px solid var(--border-color);
    border-radius: .5rem;
    outline: none;
    margin-right: 15px;
    /* padding: 2rem; */
    padding: 10px;
    background: none;
    z-index: 1;
}

.user-myprofile_form_input_acads:focus+.user-form__label {
    top: -.5rem;
    left: .8rem;
    color: var(--first-color);
    font-size: var(--small-font-size);
    font-weight: 500;
    z-index: 10;
  }

  .user-myprofile_form_input_acads:not(:placeholder-shown).user-myprofile_form_input_acads:not(:focus)+.user-form__label {
    top: -.5rem;
    left: .8rem;
    font-size: var(--small-font-size);
    font-weight: 500;
    z-index: 10;
  }

  .user-myprofile_form_input_acads:focus {
    border: 2px solid var(--first-color);
  }


  .academics-bigger__form{
    display:"flex"
  }
