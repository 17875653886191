.detailmodal_main-div::-webkit-scrollbar{
    display: none;

  }
  .detailmodal_main-div{
    background-color: #fff;
    overflow-y: scroll;

  }

  .addmodal__save-button{
  background: linear-gradient(133deg, #2AA8C4 0%, #38C1DF 100%);
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  padding-block: 16px;
  width:246px;
  /* justify-content: center;
  align-items: center; */
  /* padding: 14px 180px; */
  /* width: 100%; */
  /* text-align: center; */
  /* height: 40px; */
  /* margin-top: .5rem; */
  /* margin-bottom: .5rem; */
  }

  .detailmodal__edit-button{
    background: white;
    border-radius: 10px;
    color: #1C8EA8;
    /* justify-content: center;
    align-items: center; */
    font-size: 16px;
    /* padding: 14px 180px; */
    padding-left: 10px;
    padding-right: 10px;
    width: 200px;
    border: 1px solid #1C8EA8;
    /* text-align: center; */
    height: 40px;
    /* margin-top: .5rem;
    margin-bottom: .5rem; */
  }