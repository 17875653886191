* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    /* color-adjust: exact !important;                 Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }
  /* Header Box-->With Name PhNo And Icon Of College */
  .overlapping-table{
    margin-top: -1px;
  }
  .HeaderBox {
    width: 728px;
    height: 70px;
    flex-grow: 0;
    padding: 10px 6px 7px 5px;
    width: 272px;
    height: 28px;
    margin: 25px 220px 0 0;
  }
  .HeaderName{
    font-weight: bold;
  }
  /* Entire Body Of Resume Dimensions 210*297 */
  .ResumeFrame {
    width: max(794px,70%);
    height: auto;
    padding: 15px 36px;
    box-Shadow: 0 10px 36px rgba(0, 0, 0, .15);
    font-family: Calibri, sans-serif;
    margin:0 auto ;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 10pt;
    background-color: white;
  }
  .image-1 {
    width: 225px;
    height: 50px;
    margin: -50px 0 16px 497px;
  }
  
  .Student-Details {
    width: 272px;
    height: 8px;
    margin: 25px 220px 0 0;
    font-family: Inter;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  /* Font WEight Global Decleration---------------------------->*/
  /* <----------------------------> */
  :root {
    --orignal-size: 0.9rem;
    --font-size: 1.5rem;
  }
  .Student-Details .text-style-1 {
    font-size: 14px;
    font-weight: bold;
  }
  
  .Student-Details .text-style-2 {
    color: #0038ff;
    height: 17px;
  }
  .Text {
    margin: 25px 220px 0 0;
    width: 272px;
    height: 28px;
  }
  /* Tables Styling */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
 
  .ResumeTable {
    border-collapse: collapse;
    /* box-shadow: 0 5px 10px gray; */
    background-color: white;
    text-align: left;
    overflow: hidden;
    border-bottom-style: none;
  }
  
  .Resumetable-th {
    padding: 0.2rem 0.2rem;
    /* font-size: 14.4px; */
    text-transform: uppercase;
    background-color:rgb(191, 191, 191);
    width: 754.2px;
    height: 17px;
    letter-spacing: 0.1rem;
    /* font-size: 0.7rem; */
    font-weight: 900;
    justify-content: center;
    text-align: center;
    border-bottom: none;
    letter-spacing: normal;
    line-height: normal;
  }
  th#Topic{
    border: solid 0.25px #000;
  }
  /* th #Role #FirmName #Duration{
    text-transform:lowercase ;
  } */
  .ResumeTable-td {
    text-align: center;
    /* border: solid 0.25px #000; */
    border-bottom: none;
    padding: 0.16rem;
    letter-spacing: normal;
    line-height: normal;
    overflow-wrap:break-word ;
    /* max-width: 50px; */
  }
  table.EducationBox thead th#Degree {
    width: 16%;
  }
  
  table.EducationBox thead th#Institute {
    width: 54%;
  }
  table.EducationBox thead th#Percentage-CGPA {
    width: 18.4%;
  }
  /* not required
  table.EducationBox thead th#Year{
    width: 11.6%;
  } */
  /* table.IntershipBox thead th#Role {
    width: 16%;
  }
  table.IntershipBox thead th#FirmName {
    width: 50%; */
  /* height: 17px; */
  /* } */
  /* .new-line {
    white-space: pre-line;
  } */
  .resume-settings-Button {
    width: 102px;
    margin-top: 35px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #d9d9d9;
    margin-right: 20px;
  }
  .resume-settings-Button:hover {
    background-color: #eeee;
    border: solid 1px #eeee;
    cursor: pointer;
  }
  .Resume-Setting-Small-DropDown{
    width: 250px;
    margin-top: 25px;
    height: 28px;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    /* background-color: #f2f2f2; */
    text-align: left;
    /* margin-right: 20px; */
    direction: ltr;
    font-weight: 500;
  }

  .Resume-Setting-Small-DropDown1{
    width: 95%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    height: 28px;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    /* background-color: #f2f2f2; */
    text-align: left;
    /* margin-right: 20px; */
    direction: ltr;
    font-weight: 500;
    text-align: left;
    display: flex;
  justify-content: flex-start;
  }

  select:hover {
    background-color: #eeee;
    border: solid 1px #eeee;
    cursor: pointer;
  }
  select#fontFamily {
    width: 150px;
  }

  .MainBox{
  position: absolute;
  width: 1048px;
  height: 637px;
  left: 232px;
  top: 83px;
  background: #F2F2F2;
  }
  .EditResumeBox{
  box-sizing: border-box;
  position: relative;
  padding-left: 1rem;
  width: 262px;
  height: 810px;
  left: 655px;
  top: -77px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  }
  .ResumeSettingsHeading{
  position: absolute;
  margin: top 30px left 56px; 
  width: 232px;
  height: 39px;
  left: 22px;
  top: 30px;
  font-family: 'Roboto', 'sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 39px;
  /* identical to box height */
  color: #404040;
  }
  .ResumeDisplay{
  position: absolute;
  width: 304px;
  height: 47px;
  left: 475px;
  top: 0px;
  /* border: 1px solid #BFBFBF; */
  }
  .no-printme{
    margin-top: 100px;
  }
  .Button-div{
    margin-top: 70px;
  }
  .td-DEGREE   {
    margin: none;
  }
  .td-INSTITUTE{
    margin: none;
  }
  .td-PERCENTAGE{
    margin: none;
  }
  .td-YEAR{
    margin: none;
  }
  /* ****************** */
  /* Mini Resume CSS */
  /* ****************** */
  
  .MiniResume{
    border-bottom: 20px;
  }
  .resumeTable-ul{
    padding-left: 0px!important;
    margin-bottom: 0px!important;
    line-height: normal;
  }
  #resumeTable-p{
    margin-bottom: 0px!important;
    line-height: normal;
  }
  
/*************************/
/*Header New Css*/
/*************************/
p{
    margin-bottom: 0rem ;
    line-height: normal;
  }
.StudentDetails{
  text-align: left;
  padding: 0.3rem;
  /* padding-left: 0.5rem; */
  line-height: 0.75;
  vertical-align: baseline ;
 }
 /* .icon{
   cursor: pointer;
 } */
 .Header-Table{
   width: 728px;
   margin: 0;
   /* border: 0.5px solid #000; */
   border-bottom: none;
 }
 .Header-image{
   /* border: 0.5px solid #000; */
   padding: 0px;
   text-align: right;
   vertical-align: bottom;
   width: 30%;
   /* margin-left: 10%; */
   padding: 5px;
 }
 .image-2{
   width: 225px;
   height: 50px;
 }
 .Email{
   color: #0038ff;
 }
 .Header-Phno{
   color: #000;
 }
.Name_User-Name{
  font-size: 12pt;
}

body{
  overflow-x: auto;
}
.subHeading{
  width: 70%;
  border: none;
  padding: 10px;
  text-align: left;
  /* border-radius: .5rem; */
  border-spacing:0 15px;
  color: #000;
  background-color: #f2f2f2;
  
}
.ResumeContent-table {
  /* width:100%; */
  /* margin: 8% 0 2% 0; */
  /* margin: 8% 0 2% 15%; */
  margin: 5% 40% 2% 5%;
  border-collapse: collapse;

}
/* .subHeading:hover{
  background-color: darkgray;
  box-shadow: none;
} */
/* .ResumeContent-table-row:hover { */
/* box-shadow: 0px 0px 10px rgba(0, 230, 255, 0.2);
*/
/* box-shadow: 0 10px 36px rgba(0, 0, 0, .15); */
/* border: 1px solid #339AB1; */
/* background-color: darkgray; */
/* border-spacing: 0 .em; */
/* } */

.ResumeContent-Container {
  width: max(100%, 1350px);
  height: 100vh;
  /* overflow-x: auto; */

}

.ResumeContent-writing  {
  width: 80%;
  margin: 20px;
}
.resume-content-tips-text {
  font-style: italic;
  font-size: 12px;
  /* margin-left: 121px;
  margin-bottom: 2%; */
}

/* @media print {
  .page-break {
    display: block;
    page-break-inside: avoid;
  }
} */


.page_normal {
  /* page-break-after: always; */
  padding:2.54cm;
}
.page_narrow {
  /* page-break-after: always; */
  padding: 1.27cm;
}



.ResumeContent-table-container{
  width:100%;
  /* margin: 0 40% 2% 0; */
 
  border-collapse: separate;
  border-spacing: 0 .4em;
}


.ResumeContent-table-row {
background-color: #f2f2f2;
cursor: pointer;
/* border-radius: 0.5rem; */
}

.ResumeContent-header {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  top: 10px;
}

.ResumeContent-writing {
  position: absolute;
  display: flex;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
}
.ResumeContent-icons{
  display: flex;
}
.ResumeContent-AdjustableResume{
width:max(50%,650px);
display: inline-block;
margin-top: 2.4%;
margin-right: 1.8%;
height: auto;
}
.ResumeContentMainDiv{
height: auto;
}
.ResumeContent-icon{
width: 3%;
/* border-radius: 0px; */
}
/* .ResumeContent-table-container :hover{
background-color: yellow;
} */
.ResumeContent-table-row:hover {
background-color: darkgray;
/* border-bottom: solid 1px yellow; */
}

.ResumeContent-table-row:hover .hover {
background-color: darkgrey;
}
/* .hover{
border-radius: 0.5rem;
} */

.backandnext_single{
width: 50%;
margin: 0 3% 0 3%;
}
.backandnext_single>p{
margin-left: 72%;
}
.Picture_Button{
  display: flex;
  justify-content: center;
}
.LogoButtons{
  width: 323px;
  padding: 10px;
  font-size: 22px;
  margin: 20px 10px 10px 10px;
  background-color: #eee;
  border-radius: .8rem;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 0px;
  margin-left: 0px;
}
.LogoButtons-first{
  margin-right: 2.5%;
}
.LogoEditMode-Container{
  border:2px solid rgba(0, 0, 0, 0.05);
    text-align: center;
    margin:  1% 10%;
    margin-bottom: 2%;
    /* margin-left: max(7%,110px); */
    width: 100%;
    padding: 1rem;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    margin-left: 0px;
}
.AddLogo-p{
  font-size: 28px;
  text-align: left;
  margin-top: 1%;
}
.AddLogo-icon{
  /* width: 250px; */
  margin-top: 4%;
  margin-left:8% ;
  text-align: left;
}
.LogoEditMode-Select{
  width: 149px;
    margin-top: 25px;
    height: 28px;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #f2f2f2;
    text-align: left;
    margin-right: 20px;
    direction: ltr;
    font-weight: 500;
}
.LogoEditMode-Label{
  text-align: left; 
  margin-left: 0px!important;
}
.LogoEdit-CheckBox{
  /* height: 18px; */
  /* width: 18px; */
  /* margin-top: 25px; */
}
.checkboxes{
  text-align: left;
}
.checkboxes label {
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;
  margin-top: 40px;
}
.checkboxes input {
  vertical-align: middle;
}
.checkboxes label span {
  vertical-align: middle;
}
.upload-img{
  width: 30px;
  height: 30px;
}
.ResumeContent-Resume{
  font-size: 9.5pt;
}