.OngoingEventsCard {
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08); */
border-radius: 10px;
/* margin-left: 5em; */
overflow: hidden;
}

.generalTextForOngoing {
    color: primary1;
}

.container {
    display: flex;
    --bs-gutter-x:0;
    margin: .5rem 0;
}

.containerHeadings {
    display: flex;
    --bs-gutter-x:0;
    justify-content: space-between;
}

.cardContainer {
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08) !important; */
}

.adminTableCell {
    color: #8b8b8b;
}

.topHeadings {
    font-size: 32px;
}

.topHeadings1 {
    font-size: 24px;
    margin: 2px 0;
    white-space: 'nowrap',
}

.cardsInsideFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    padding-left: .5rem;
    padding-bottom: .5rem;
}

.providingSpace {
    margin-left: 0.5em;
    font-size: 16px;
    color: #545454;
}

.providingSpace1 {
    font-size: 16px;
    color: #545454;
}

.providingSpaceForSubDetails {
    margin-left: 0.5em;
    font-size: 16px;
    color: #1C8EA8;
}

.providingSpaceForSubDetailsBlack {
    margin-left: 2em;
    font-size: 16px;
    color: black;
}

.providingSpaceForSubDetailsRed {
    margin-left: 0.5em;
    font-size: 16px;
    color: #FF0000;
}

.noUpcomingBookings {
    background: #FFFFFF;
/* Card Shadow */
display: flex;
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
border-radius: 10px;
height: 100px;
align-items: center;
justify-content: center;
margin-bottom: 1.5rem;
  }

  .noUpcomingBookingsText {
    color: #1C8EA8;
    font-size: 24px;
    text-align: center;
  }

  /* group discussion details */
  .group-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: .3rem 0;
}

.group-providingSpace1 {
    flex: 1;
    text-align: left;
    color:#545454
}

.group-providingSpaceForSubDetailsBlack {
    flex: 2;
    text-align: left;
}

.group-providingSpaceForSubDetails {
    flex: 2;
    text-align: left;
    color: #1C8EA8;
}


