.ModalSt-contain
{
    width: 100%;
    height: 100%;
    float: right;
    /* position: absolute; */
    right: 0;
   
}
.st-ticket-number
{
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.0025em;

}

.st-chat-now
{
    background: linear-gradient(132.73deg, #2AA8C4 16.39%, #38C1DF 100%);
    border-radius: 10px;
    width: 100%;
    height: 38px;
    border: none;

}

.st-close-chat
{
    border-radius:10px;
    border: none;
    width: 176px;
    height: 40px;
    cursor: pointer;
    margin-top: .5rem;
    background: #E6E6E6;
    cursor: pointer;
}

.modal-subtitles-style
{
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.15px;
color: #000000;
}

.modal-subtitles-style2 {
    font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.15px;
color: #000000;
margin-top: 1rem;
}

.modal-subtitles-style1 {
    font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.15px;
color: #000000;
margin-bottom: 1.5rem;

}

.modal.show
{
    /* position: absolute; */
    right: 0;
}

.btn-add-bg
{
    background: linear-gradient(132.73deg, #2AA8C4 16.39%, #38C1DF 100%);
    color: #fff;
}

.btn-add-border
{
    background: #fff;
    border: 2px  solid  #2AA8C4;
}

.label-grey-text
{
    color:#8B8B8B;
}