td {
  border: none;
  /* height: 35px; */
}

.tooltip_css-rt .tooltiptext-rt {
  visibility: hidden;
  width: 400px;
  background-color: rgb(116, 107, 107);
  font-size: 12px;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  bottom: 100%;
  left: 50%;
  font-family: roboto, sans serif;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip_css-rt:hover .tooltiptext-rt {
  visibility: visible;
}

body {
  overflow-x: auto;
}

.resume-template-edit {
  font-size: 20px;
  /* margin-bottom: 5%; */
}



.i-icon {
  width: 16px;
  /* height: 18px; */
  /* border-radius: 70%; */
  /* border: 1px solid grey; */
  /* background-color: white; */
  /* position: relative;
  left: 100px; */
}

.pecil-icon {
  width: 21px;
  /* height: 22px; */
}

tr#resume-template-tr {
  border-bottom: 0.5pt solid #E6E6E6;

}

td.icon {
  width: 2%;
}

.icon {
  /* border-bottom: 1px solid black; */

}

/* .icon:hover{
  background-color: #f2f2f2;
} */
.save-And-Next-Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: "none";
  gap: 10px;
  border-radius: 10px;
  width: 536px;
  height: 52px;
}

.editOrSave-btn {
  background-color: transparent;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.editOrSave-btn:hover {
  background-color: #E6E6E6;
}

/* .editOrSave-btn:disabled{
  background-color:lightgrey;
} */

/* i{
  hover:pointer-events
} */
/* i{
    background-color: white;
    border-radius: 70%;
    border: 1px solid grey;
    padding: 10px;
} */

/* td::after{ 
  border-bottom: 1px solid #000;
} */
.resume-template-input {
  width: 100%;
  border: none;
  outline: none;
  background-color: #f2f2f2;
  font-size: 16px
    /* font-size: "30px"; */
    /* background-color: #fff; */
}

.resume-template-content {
  /* margin-top: max(15%, 150Px); */
  width: max(70%, 800px);
  margin-right: 15%;
  margin-left: max(15%, 105px);
  /* margin-top: 10%; */
  /* margin-top: 10%; */

}

.resume-template-content-modal {
  width: 600px;
  margin: 0 auto;
}

.resume-template-content-modal1 {
  width: 600px;
  margin: 0 7rem;
}


.resume-template-input:focus {
  outline: none;
  background-color: #f2f2f2;
  font-size: 20px;
}

.edit-template {
  font-family: 'Roboto', sans-serif;
}

.resume-template-draggable {
  cursor: grabbing;
  transition: top 0.4s ease-out, left 0.4s ease-out;
}

.resume-template-draggable[slected*="yes"] {
  cursor: grab;
  transition: none
    /* transition: top 0.4s ease-out, left 0.4s ease-out; */
}



.resume-template-draggable:hover {
  background-color: #f2f2f2;
  /* transition: top 0.4s ease-out, left 0.4s ease-out; */
  cursor: grab;
}

.resume-template-draggable:active {
  /* background-color: #f2f2f2; */
  cursor: grabbing;

}

.next-button {
  margin-left: max(81%, 420px);
}

.back-and-next-button {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  /* margin: auto; */
}


/* .resume-template-draggable:active {background-color: #000;} */
.resume-template-complete {
  font-family: 'Roboto', sans-serif;
  /* cursor: grab; */
  width: 100vw;
  /* overflow-x: auto; */
  height: 100%;
}

/* bhoomika  */

.table-row {
  height: 50px;
}

.resume-template-add-section-div {
  height: 3%;
  width: 100%;

}

#add {
  border: "none";
  font-size: "16px";
}

#add:hover {
  border: "none";
  background-color: transparent;
}

#add-more-button {
  float: left;
  border: none;
  /* margin: 2% 15% 0 0; */
  margin-top: 2%;
  margin-left: max(77%, 850px);

  /* margin:  */
  /* background-color: white; */
}

#add-more-button:hover {
  color: black;
}

#add-more-button:focus {
  color: black;
  border: none;
  outline: none;
  box-shadow: none;

}


.resume-template-p {
  margin-bottom: 0;
}



tr-draggable:hover {
  /* border-bottom: 1px solid rgb(255, 255, 255); */
  border: none;

}


/* SUBHAMS */
.Heading {
  margin: auto;
  width: 100%;
  /* margin-left:120px ; */
  /* //Comment this */
  padding: 8px;
  font-size: 26px;
}

.sub-heading {
  width: 100%;
  border: none;
  height: 35px;
  margin-bottom: 10px;
}

.i-icon {
  /* width: 16px;
  height: 18px;  */
  /* border-radius: 70%; */
  /* border: 1px solid grey; */
  /* background-color: white; */
  /* position: relative;
  left: 100px; */
}

.pencil-icon {
  width: 21px;
  height: 22px;
}

.pencil-icon:disabled {
  color: lightgrey;
}

td.icon {
  width: 2%;
}


.resumeTemplate-back-and-next-button {
  margin-top: 2%;
}



.resumeTemplate-button-Next {
  margin-top: 20px;
  margin-left: 820px;
}

.resumeTemplate-button-Next:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, .15);
  background-color: #00E6FF;
}

.resumeTemplate-button-Back {
  margin-top: 20px;
  /* margin-left: 820px; */
  background-color: #fff;
  border: 1px solid #00E6FF;
  color: #00E6FF;
}

.resumeTemplate-button-Back:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, .15);
  background-color: #339ab1;
  color: white;
  border: 1px solid #339ab1;
}

.tooltip_css {
  margin-left: 10px;
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip_css .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(116, 107, 107);
  font-size: 12px;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  bottom: 100%;
  left: 50%;
  font-family: roboto, sans serif;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}



.tooltip_css:hover .tooltiptext {
  visibility: visible;
}

.lockMechanism {
  height: 20px;
  width: 20px;
}

.HeadingsBaseTemplate {
  text-align: center;
}

.subHeadingsBaseTemplate {
  font-size: 14px;
  color: #8B8B8B;
  white-space: nowrap;
  margin-top: .5rem;
  margin-bottom: .5rem;
}
