body{
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
}

@font-face {
    font-family: 'Calibri';
    src: local('Calibri'), url('https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY6w&skey=a1029226f80653a8&v=v15') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Garamond';
    src: local('Garamond'), url('https://fonts.gstatic.com/l/font?kit=XoHl2Y_-T6Oo88RDZSQOn9I&skey=a3a4b3361b12223a&v=v18') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
.QuickSettings-SettingBox{
    /* border:2px solid rgba(0, 0, 0, 0.05); */
    text-align: center;
    margin:  2% 10%;
    margin-left: max(7%,110px);
    width: 453px;
    /* padding: 1rem; */
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    height: 400px;
}

.QuickSettings-SettingBox1{
    /* border:2px solid rgba(0, 0, 0, 0.05); */
    text-align: center;
    /* margin:  2% 10%; */
    /* margin-left: max(7%,110px); */
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: .7rem;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    height: 400px;
}
.QuickSettings-label{
    margin: 2%;
    display: inline;
    text-align: start;
    text-align: left;
}

.QuickSettings-label1 {
    display: inline;
    text-align: start;
    text-align: left;
    margin-top: 1rem;
}

.QuickSettings-Resume{
    position: absolute;
    top :6.5px;
    left: max(35%,610px);
    right: max(10%,200px);
    height: auto;
    padding: 1%;
    margin-bottom: 10%;
    width: 810px;
    text-align: left;
}
.ResumeDropDown{
    text-align: right;
    width: 100%;
    align-items: right;
    margin-right: 2rem;
}
.ResumeDropDownHeading{
    text-align: left;
    width: 100%;
    
}

.ResumeDropDownHeading2{
    text-align: left;
    width: 100%;
    margin-top: 1rem;
}
.position-save-button{
    float: left;
    left: max(85%,1300px);
    margin-top: 0%;
    cursor: pointer;
    position: absolute;
}
/* .Resume-Settings-Content{
    width: max(100%, 1000px);
    overflow: hidden;
    overflow-x: auto;
   
} */

/* .resumeSettingsContainer {
    width: max(100%, 900px);
    overflow: hidden;
    overflow-x: auto;
    height: 100vh;
} */
.profile-image{
    width:50px;
    height:50px;
    margin-top: 5%;
}
.Left{
    float:"left"
}
.CenterPhoto{
    float:left;
    /* width:100px; */
}
.FirstName-Adress{
    float:left;
    margin:auto;
    width:33%;
    margin-bottom:0.5%;
    /* width:100px; */
}
.SecondName-Adress{
    margin:  auto;
    width:33%;
    margin-bottom:0.5%;
    text-align:center;
    /* width:100px; */
}
.ThirdName-Adress{
    float:right;
    width:33%;
    margin:auto;
    margin-bottom:0.5%;
    text-align:right;
}
.image-2{
    margin-bottom:2%;
}

.lockMechanism {
    margin-left: 1rem;
}

