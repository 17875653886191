body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /*===== Colores =====*/
  --primary-color: ;
  --secondary-color: ;
  --tertiary-color: ;
  --submit-btn-color:#38C1DF;

  /*===== font size =====*/
  --small-font-size: .75rem;
  --medium-font-size: .9rem;
  --font-size : 32px;
  --body-font: 'Roboto', sans-serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
}

.login-form__button {
  width: 250px;
  height: 100px;
  padding: .75rem 2rem;
  outline: none;
  border: none;
  background-color: var(--before-button-hover);
  color: #fff;
  font-size: var(--normal-font-size);
  border-radius: .5rem;
  cursor: pointer;
  transition: .3s;
  position: absolute;
  margin-bottom: 3rem;
}

.signup-button {
  background: none;
  border: none;
  color: #561D5E;
  text-decoration: none;
  margin-left: .5rem;
}

.show-hide-button {
  background: none;
  border: none;
  color: #1C8EA8;
  text-decoration: none;
  margin-right: .5rem;
  font-size: 14px;
}

.edit-save-button {
  background: none;
  border: none;
  color: #1C8EA8;
  text-decoration: none;
  margin-right: .5rem;
  font-size: 20px;
}

.saveButtonResumesDigital {
  background: linear-gradient(92.04deg, #561D5E -18.01%, #8A4893 144.6%);
border-radius: 10px;
/* flex-direction: row; */
justify-content: center;
align-items: center;
padding: 14px 50px;
/* gap: 10px; */
color: white;
width: 231px;
height: 52px;
border: none;
}


.saveButtonResumesDigital1 {
  background: linear-gradient(92.04deg, #561D5E -18.01%, #8A4893 144.6%);
border-radius: 10px;
/* flex-direction: row; */
justify-content: center;
align-items: center;
padding: 14px 180px;
/* gap: 10px; */
text-align: center;
color: white;
/* width: 231px; */
height: 52px;
border: none;
}

.MuiTableCell-root {
  border-bottom: none;
}



.Rephrasebutton {
  color: #561D5E;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin-right: 1rem;
  background: white;
  border-radius: 11px;
  border: 1px solid #561D5E;
  font-family: 'Roboto', sans-serif;
  /* border: none; */
}

.RephrasebuttonNoBackground {
  background: linear-gradient(92.04deg, #561D5E -18.01%, #8A4893 144.6%);;
  justify-content: center;
  color: white;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  border: none;
  margin-right: 1rem;
  border-radius: 11px;
  font-family: 'Roboto', sans-serif;
}

.saveButtonResumesDigitalBaseTemplate {
  background: white;
  border-radius: 10px;
  color: #561D5E;
  justify-content: center;
  align-items: center;
  /* padding: 14px 180px; */
  width: 100%;
  border: 1px solid #561D5E;
  text-align: center;
  height: 52px;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.saveButtonCareercarve {
  background: white;
  border-radius: 10px;
  color: #1C8EA8;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* padding: 14px 180px; */
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border: 1px solid #1C8EA8;
  text-align: center;
  height: 40px;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.saveButtonResumesDigitalBaseTemplate:focus {
  background: #AB92BF;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  /* padding: 14px 180px; */
  width: 100%;
  border: none;
  text-align: center;
  height: 52px;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

/* .saveButtonResumesDigitalBaseTemplate:hover {
  background: #AB92BF;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  text-align: center;
  height: 52px;
  margin-top: .5rem;
  margin-bottom: .5rem;
} */

.saveButtonResumesDigitalBaseTemplateSelected {
  background: #AB92BF;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  /* padding: 14px 180px; */
  width: 100%;
  border: none;
  text-align: center;
  height: 52px;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.saveButtonResumesDigitalBaseTemplate1 {
  background: linear-gradient(92.04deg, #561D5E -18.01%, #8A4893 144.6%);
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  /* padding: 14px 180px; */
  width: 90%;
  border: 1px solid #561D5E;
  text-align: center;
  height: 52px;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media print {
  .page:first{
    size: auto;
  margin-top: 0;
  margin-bottom: 1rem;
  }
  .page {
    size: auto;
    margin-top: 4.2%;
    margin-bottom: 1rem;
  }
}

.Previousbutton {
  color: #fff;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 184.5px;
  height: 35px;
  margin: auto;
  background: #f5a536;
  border-radius: 11px;
  border: 1px solid #f5a536;
  font-family: 'Roboto', sans-serif;
}

.Submitbutton {
  color: white;
  justify-content: center;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  width: 227px;
  height: 30px;
  background: #f5a536;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.form-group-session {
  margin-bottom: 4px;
}