
.headerPart
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1%;
   
}
.closeIcon
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: center;
}
.closeIcon:hover {
    background-color: lightgray;
    cursor: pointer;
  }

.DeleteConfirm {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    font-size:18;
    font-weight: bold;
    text-shadow: 1px 1px 2px #333;

  }

.Btn
{
    background-color:var(--submit-btn-color);
    width: 30%;
    height: 40px;
    border-color: var(--submit-btn-color);
    border:0;
    color: white;
    justify-content: space-between;

}
.btns
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.message
{
    font-size: 13;
    margin: 0 auto;
    text-align: center;
}
.msgContain
{
width: 100%;
height:30%;
margin-top: 8%;

}