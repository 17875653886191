.top-header
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.top-header-title
{
    margin: 0 auto;
    font-size:25px;
    margin-right:45%;
}

.form-group .form-labels
{
    font-size: 18;

}
.div-form-container
{
    margin-top: 3%;
}

.form-input-text
{
    background-color:rgba(167, 167, 167,.7);
}


.form-input-text::placeholder
{
    font-size: 18;
    color:black

}

.top-header-close
{
    margin-top: 1%;
}


.FileUploadModal 
{
    justify-items: center;
    align-items: center;
    align-self: center;
    align-content: center;
    width: 100%;
    height: 100%;
}