.switch {
    position: absolute;
    display: inline-block;
    width: 39px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #ccc;
    transition: 0.4s;
}

.slider::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    transition: 0.4s;
}
.slider:checked::before {
    background-color: lightgray;
    transition: 0.4s;
}
.switch-input:checked+.slider {
    background-color: #D0BCFF;
}

/* .switch-input:checked[type=checkbox][disabled]+.slider[disabled]{
    background-color: lightgray ;
} */
.switch-input:checked+.slider::after {
    background-color: lightgrey;
    
}

.switch-input:checked+.slider::before {
    background-color: #381E72;
    transform: translateX(15px);
}
.switch-input+.slider::before {
    background-color: #8B8B8B;
}

/* .switch-input:checked:disabled + .slider::after{
    background-color: lightgrey;
} */
.slider.round {
    border-radius: 34px;
    background-color: lightgrey;
}

.slider.round::before {
    border-radius: 50%;
    
}
.slider {
    background-color: lightgrey;
}
.slider:checked {
    background-color: #D0BCFF;
    
}

.sub-heading {
    margin: auto;
    width: 50%;
    /* border-bottom: 1px solid black; */
    padding: 10px;
    text-align: left;
}

/* input{ */
/* width: 700px; */
/* border:none; */
/* } */
/* input:focus{
    outline:none
  } */