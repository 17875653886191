.box__jdDescription {
    width: 90%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    border-radius: 20px;
    border: 1px solid var(--Neutral-Light-Grey, #E6E6E6);
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}

.typography__jdDescription {
    color: var(--Neutral-Dark-Grey, #545454);
    /* Body Large - Roboto 16/24 . +0.5 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.5px;
}

.typography__location_stipend_openings {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    color: #545454;
}

.box__jd_skills {
    width: 177.25px !important;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    /* gap: 16px !important; */
    /* flex: 1 0 0; */
    border-radius: 27px;
    border: 1px solid var(--Neutral-Dark-Grey, #545454);
}