.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader>div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #6C63FF;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1; 
    transform: translateY(-16px);
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
  opacity: 0.7; 

}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
  opacity: 0.5; 

}