.box__fileUpload {
    border: 1px solid var(--Neutral-Mid-Grey, #8B8B8B);
    background: #F5F5F5;
    justify-content: space-between;
    display: flex;
    padding: 8px;
    align-items: center;
    align-self: stretch;
}

/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.page {
    width: 300px;
    height: 200px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    display: none;
    transition: transform 0.5s ease-in-out;
}

.page.active {
    display: block;
}

.page.active:nth-child(1) {
    transform: translateX(0%);
}

.page.active:nth-child(2) {
    transform: translateX(-100%);
} */