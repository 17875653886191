.card__jobPostings {
    width: 100%;
    height: auto;
    min-height: 240px;
    display: flex;
    padding: 16px 24px 32px 24px;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    align-self: stretch;
    border-radius: 30px !important;
    border: 1px solid var(--Neutral-Light-Grey, #E6E6E6) !important;
    background: var(--Neutral-White, #FFF) !important;

    /* Neutral Black Shadow */
    box-shadow: 6px 15px 33px 0px rgba(0, 0, 0, 0.10) !important;
}

.button__viewDetails {
    border-radius: 10px !important;
    background: var(--Primary-Gradient, linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)) !important;
    box-shadow: none !important;
    min-width: 246px;
    min-height: 40px;
}