
/* .topbar-user-profile-image {
  margin-left: 880px;
} */

.topbar-main-container {
    width: 100%;
    background-color: #fff;
    height:max(8%, 80px);
    position: fixed;
    top:0%;
  overflow: hidden;
  z-index: 100;
    /* border: 1px solid red; */
   

}
.Arrow-back-btn:hover
{
  cursor: pointer;
}

.topbar-container-no-logo {
    display: flex;
    width: 90%;
    justify-content: space-between;
    /* border: 1px solid red; */
    
    /* padding-left: 12%; */
    margin-top: 0;
    
  }

.topbar-user-profile-image-no-logo {
  margin: aut0 0 auto auto;
}

.topbar-heading-no-logo {
    font-family: 'Roboto', sans-serif;
   
  }
  .topbar-heading-font-no-logo {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
  }
  