.chatModalSt-contain
{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    float: left;
}

.chat-container {
    max-height: max(80%, 550px); /* Set a maximum height for your chat container */
    overflow-y: scroll; /* Add a scroll bar only when needed */
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none;
    margin-bottom: 1rem; /* Hide the scrollbar for IE/Edge */
  }
  
  /* Hide the scrollbar for WebKit browsers (Safari, Chrome) */
  .chat-container::-webkit-scrollbar {
    width: 0;
    display: none;
  }