
.bordered-btn{
    border: 1px solid #1C8EA8;
border-radius: 10px;
width:229.5px ;
background:white;
color: #1C8EA8;

}

.backgrounded-btn
{
    background: linear-gradient(132.73deg, #2AA8C4 16.39%, #38C1DF 100%);
border-radius: 10px;
width: 229.5px;

}

.DeleteConfirm
{
    text-shadow: none;
}