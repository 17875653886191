.analytics-card-content {
    border-radius: 30px;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
    max-width: 100%; 
    margin-right: 20px;
    margin-bottom: 1.5rem
}

.displayingFlex {
    display: flex;
}

.allTheNames {
    font-size: 28px;
    color: #1C8EA8;
    text-align: center;
    margin-bottom: .5rem;
}

.allTheNames1 {
    font-size: 20px;
    color: black;
    text-align: center;
    margin-bottom: .5rem;
}

.allTheNames2 {
    font-size: 18px;
    color:#8B8B8B;
    text-align: center;
    margin-bottom: .5rem;
}

.allTheNames3 {
    font-size: 18px;
    color:red;
    text-align: center;
    margin-bottom: .5rem;
}

.allTheNames4 {
    font-size: 24px;
    color: #1C8EA8;
    text-align: center;
    margin-bottom: .5rem;
}

.analyticsMainDiv {
    padding: 20px;
}

.studentCompletionData {
    display: flex;
    justify-content: center;
}

.flexingOfData {
display: flex;
/* justify-content: center; */
align-items: center;
}

.student_details_info {
    font-size: 20px;
    color: #41B8D5;
    align-items: center;
}

.color-red {
    color: red;
}

.student_details_info {
    font-size: 20px;
    color: #41B8D5;
    /* color:#a3a3a3f6; */
}

.numberOfHireReady {
    margin-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;
}

.interviewWiseCenterData {
    display: flex;
    justify-content: space-evenly;
}

.textCenterAlign {
    text-align: center;
}

.textLeftAlign {
    text-align: left;
    font-size: 11px;
}

.collegeAnalyticsMainDiv {
    width: 80%;
    justify-content: center;
    align-items: center;
}

.BatchWiseANalytics {
    margin: 10rem;
    margin-top: 1rem;
}

/* .italicFontSubheading {
    variant="body1" style={{color: "#525252", fontStyle: "italic"}}
} */
/* 
.colorOfSpecialization {
    color: #8A8A8A;
    font-size: 9px;
} */

.body1font {
    font-size: 10px;
    text-align: center;
    color: #FF3131;
}

.body1font2 {
    font-size: 10px;
}

.ag-cell-hover {
    background-color: #41B8D5 !important; 
  }
  
  .ag-column-hover {
    background-color: #41B8D5 !important; 
  }

  /* #1f8fa9, #304758 */