.form-group
{
margin-top: 1.5rem;
}
.btn-selected:focus
{
    background-color: var(--submit-btn-color);
}

.groupSessionCompleteData {
    display: flex;
    justify-content: space-around;
}

/* .groupSession {
    margin-right: 2rem;
} */

.btn-selected
{
    background-color: var(--submit-btn-color);
}
.Modal.Header
{
    visibility: hidden;
    display: none;
    
}
                   
label span
{
    color: red;
}
.labelContent
{
    font-size: 18px;
    font-weight: bold;
    height: 18;
    margin-bottom: 1rem;
}

input::placeholder {
    color: gray;
    /* padding-left: 20px; */
    font-size: 18;
  }
.headerComp
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1%;
   
}
.inputFields
{
    height: 40px;
}


.closeIcon
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: center;
}
.closeIcon:hover {
    background-color: lightgray;
    cursor: pointer;
  }

.headerText {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    font-size:28px;
    /* font-weight: bold; */
    text-shadow: 1px 1px 2px #333;

  }

.submitBtn
{
    background-color:var(--submit-btn-color);
    width: 100%;
    height: 40px;
    border-color: var(--submit-btn-color);
    border:0;
    border-radius: 10px;
    color: white;
    bottom: 0;
    /* margin-top: 1rem; */
}

.submitBtn1 {
    /* background-color:var(--submit-btn-color); */
    width: 100%;
    height: 40px;
    border-color: var(--submit-btn-color);
    border:0;
    border-radius: 10px;
    color: black;
    bottom: 0;
}

.fileUpload
{
   
    flex-direction: row;
    display: flex;
    height: 75px;
    align-items: center;
    justify-content:center;
    margin: 0 auto;
    width: 94%;
    margin:5% 3% 3% 3%;
    /* margin: left 5px; */
    border-radius: 10px;
    background-color: rgba(56 ,193 ,223, 0.22);
}
.fileUpload p
{
    margin: 0 auto;
    margin-left: 5px;
    font-size: 16;
    

}

/* styles of input form */
.options-value {
    color: grey;
    font-size: 16px;
}