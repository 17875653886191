.pTagsHere {
    font-size: 16px;
    margin: 4px 4px 4px 20px;
}

.textTagsHere {
  font-size: 16px;
    margin: 8px 8px 8px 20px;
}

.feedbackNotProvided {
    margin-bottom: 1.5rem;
    color: #D9D9D9;
}

searchingText::placeholder {
  opacity: 1; /* 1 */
}